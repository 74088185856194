<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-breadcrumbs :items="crumbs" class="my-breadcrumbs pa-2">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          v-if="item.projectSelect"
          class="breadcrumb-item-project">
        <project-menu-select v-model="project" />
      </v-breadcrumbs-item>
      <v-breadcrumbs-item
          v-else
          :to="item.to"
          :disabled="item.disabled"
          class="breadcrumb-item">
        {{ item.text | capitalize }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>



<script>
import { mapFields } from "vuex-map-fields"

import ProjectMenuSelect from "@/project/ProjectMenuSelect.vue"

export default {
  name: "SettingsBreadCrumbs",

  props: {
    value: {
      type: Array,
      default: function () {
        return []
      },
    },
    organization: {
      type: Array,
      default: function () {
        return []
      },
    },
  },

  components: {
    ProjectMenuSelect,
  },

  computed: {
    project: {
      get() {
        return this.value[0]
      },
      set(value) {
        this.$emit("input", [value])
      },
    },
    crumbs() {
      return [
        {
          text: "Settings",
          disabled: false,
        },
        {
          projectSelect: true,
        },
        {
          text: this.meta.title,
          disabled: false,
        },
      ]
    },
    ...mapFields("route", ["query", "params", "meta"]),
  },
}
</script>


<style scoped>
.breadcrumb-item-project {
  padding: 10px 15px;
  border-radius: 4px;
}

.breadcrumb-item {
  padding: 10px 15px;
  border-radius: 4px;
  background: #FF1493;
  color: #ffffff;
}
</style>
